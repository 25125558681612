<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="blog-contact-cnt">
    <router-link :to="{name: 'PrivacyPolicyPage'}">
      <div class="nav-item">{{ $t('privacy-policy') }}</div>
    </router-link>
    <router-link :to="{name: 'TermsAndConditionsPage'}">
      <div class="nav-item">{{ $t('terms-and-conditions') }}</div>
    </router-link>

  </div>
</template>
