import {createRouter, createWebHistory} from 'vue-router'
import store from './../store';

const paths = {
    'TrustedAgenciesPage': {en: '/trusted-agencies', bg: '/коректни-агенции'},
    'AboutUsPage': {en: '/about-us', bg: '/за-нас'},
    'TermsAndConditionsPage': {en: '/terms-and-conditions', bg: '/общи-условия'},
    'PrivacyPolicyPage': {en: '/privacy-policy', bg: '/политика-за-поверителност'},
    'SelectCleaningServicePage': {en: '/cleaning/services', bg: '/почистване/услуги'},
    'SelectMovingServicePage': {en: '/moving/services', bg: '/преместване/услуги'},
    'CleaningPage': {en: '/cleaning', bg: '/почистване'},
    'MovingPage': {en: '/moving', bg: '/преместване'},
    'InteriorPage': {en: '/interior', bg: '/интериор'},
    'VendorDashboard': {en: '/vendor/dashboard', bg: '/доставчик/табло'},
    'Profile': {en: '/profile', bg: '/профил'},
        'AddProperty': {en: 'property', bg: '/имот'},
        'EditProperty': {en: 'property/:id', bg: 'имот/:id'},
        'AdminPropertyList': {en: 'properties', bg: 'имоти'},
        'AdminListProducts': {en: 'products', bg: 'продукти'},
        'AdminVendorProductsImport': {en: 'products-import', bg: 'импорт-на-продукти'},
        'AdminVendorOrders': {en: 'orders', bg: 'поръчки'},
        'AdminVendorSingleOrder': {en: 'orders/:id', bg: 'поръчки/:id'},
        'AdminAddProduct': {en: 'product', bg: 'продукт'},
        'ViewingRequests': {en: 'viewing-requests', bg: 'заявки-за-огледи'},
        'UserEditProduct':  {en: 'product/:id', bg: 'продукт/:id'},
        'AdminRoommates':  {en: 'roommates-posts', bg: 'обяви-за-съквартиранти'},
        'EditRoommateOffer':  {en: 'roommates-posts/:id', bg: 'обяви-за-съквартиранти/:id'},
        'AdminSettings':  {en: 'settings', bg: 'настройки'},
        'AgencyDashboard':  {en: 'dashboard', bg: 'табло'},
    'MarketplaceProductCategories':  {en: '/product/category', bg: '/продукти/категории'},
    'MarketplaceProductPromoCategories':  {en: '/product/category/promo', bg: '/продукти/категории/промоции'},
    'SingleProductPage':  {en: '/product/:id', bg: '/продукт/:id'},
    'Cart':  {en: '/cart', bg: '/покупки'},
    'FavoriteProducts':  {en: '/favorites', bg: '/любими'},
    'PropertySearchPage':  {en: '/properties', bg: '/имоти'},
    'SinglePropertyPage': {en: '/property/:id', bg: '/имот/:id'},
    'SearchRoommateResult': {en: '/roommates', bg: '/съквартиранти'},
    'SingleRoommateOffer': {en: '/roommates/:id', bg: '/съквартиранти/:id'},
    'AddRoommate': {en: '/roommates/post', bg: '/съквартиранти/обява'},
    'Contacts': {en: '/contacts', bg: '/за-контакти'},
    'SingleVendor': {en: '/vendor/:id', bg: '/вендор/:id'},
    'User': {en: '/user/:id', bg: '/потребител/:id'},
    'MarketplaceCategoryPage': {en: '/product/category/:id', bg: '/продукти/категория/:id'},
    'Agency': {en: '/agency/:id', bg: '/агенция/:id'},
    'Marketplace': {en: '/shop', bg: '/магазин'},
}

const lang = 'bg';

function getPath(name) {
    return encodeURI(paths[name][lang]);
}

const routes = [
    {
        path: getPath('TrustedAgenciesPage'),
        name: 'TrustedAgenciesPage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/TrustedAgenciesPage/TrustedAgenciesPage')
    },
    {
        path: getPath('AboutUsPage'),
        name: 'AboutUsPage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/AboutUsPage/AboutUsPage')
    },
    {
        path: getPath('TermsAndConditionsPage'),
        name: 'TermsAndConditionsPage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/TermsAndConditionsPage/TermsAndConditionsPage')
    },
    {
        path: getPath('PrivacyPolicyPage'),
        name: 'PrivacyPolicyPage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/PrivacyPolicyPage/PrivacyPolicyPage')
    },
    {
        path: '/coming-soon',
        name: 'ComingSoonPage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/ComingSoonPage/ComingSoonPage')
    },
    {
        path: getPath('SelectCleaningServicePage'),
        name: 'SelectCleaningServicePage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/SelectCleaningServicePage/SelectCleaningServicePage')
    },
    {
        path: getPath('SelectMovingServicePage'),
        name: 'SelectMovingServicePage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/SelectCleaningServicePage/SelectCleaningServicePage')
    },
    {
        path: getPath('MovingPage'),
        name: 'MovingPage',
        meta: {transition: 'slide-left'},
        // component: () => import('../views/MovingCleaningPage/MovingCleaningPage')
        component: () => import('../views/CleaningPage/CleaningPage')
    },
    {
        path: getPath('CleaningPage'),
        name: 'CleaningPage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/CleaningPage/CleaningPage')
    },
    {
        path: getPath('InteriorPage'),
        name: 'InteriorPage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/InteriorPage/InteriorPage')
    },
    {
        path: getPath('Profile'),
        name: 'Profile',
        component: () => import('../views/ProfileContainer/ProfileContainer'),
        children: [
            {
                path: getPath('AddProperty'),
                name: 'AddProperty',
                component: () => import('../views/AdminAddProperty/AdminAddProperty')
            },
            {
                path: getPath('EditProperty'),
                name: 'EditProperty',
                component: () => import('../views/AdminAddProperty/AdminAddProperty')
            },
            {
                path: getPath('AdminPropertyList'),
                name: 'AdminPropertyList',
                component: () => import('../views/AdminPropertyList/AdminPropertyList')
            },
            {
                path: getPath('VendorDashboard'),
                name: 'VendorDashboard',
                component: () => import('../views/AdminDashboard/AdminDashboardVendor/AdminDashboardVendor')
            },
            {
                path: getPath('AgencyDashboard'),
                name: 'AgencyDashboard',
                component: () => import('../views/AdminDashboard/AdminDashboardProperty/AdminDashboardProperty')
            },
            {
                path: getPath('AdminSettings'),
                name: 'AdminSettings',
                component: () => import('../views/AdminSettings/AdminSettings')
            },
            {
                path: getPath('AdminAddProduct'),
                name: 'AdminAddProduct',
                component: () => import('../views/AdminAddProduct/AdminAddProduct')
            },
            {
                path: getPath('UserEditProduct'),
                name: 'UserEditProduct',
                component: () => import('../views/AdminAddProduct/AdminAddProduct')
            },
            {
                path: getPath('AdminListProducts'),
                name: 'AdminListProducts',
                component: () => import('../views/AdminVendorProducts/AdminVendorProducts')
            },
            {
                path: getPath('AdminVendorProductsImport'),
                name: 'AdminVendorProductsImport',
                component: () => import('../views/AdminVendorProductsImport/AdminVendorProductsImport')
            },
            {
                path: getPath('AdminVendorOrders'),
                name: 'AdminVendorOrders',
                component: () => import('../views/AdminVendorOrders/AdminVendorOrders')
            },
            {
                path: getPath('AdminVendorSingleOrder'),
                name: 'AdminVendorSingleOrder',
                component: () => import('../views/AdminVendorSingleOrder/AdminVendorSingleOrder')
            },
            {
                path: getPath('ViewingRequests'),
                name: 'ViewingRequests',
                component: () => import('../views/AdminViewingRequests/AdminViewingRequests')
            },
            {
                path: getPath('AdminRoommates'),
                name: 'AdminRoommates',
                component: () => import('../views/AdminRoommates/AdminRoommates')
            },
            {
                path: getPath('EditRoommateOffer'),
                name: 'EditRoommateOffer',
                component: () => import('../views/AddRoommateOffer/AddRoommateOffer')
            },
        ]
    },
    {
        path: getPath('MarketplaceProductCategories'),
        name: 'MarketplaceProductCategories',
        component: () => import('../views/MarketplaceProductCategories/MarketplaceProductCategories')
    },
    {
        path: getPath('MarketplaceProductPromoCategories'),
        name: 'MarketplaceProductPromoCategories',
        component: () => import('../views/MarketplaceProductCategories/MarketplaceProductCategories')
    },
    {
        path: '/blog/category/:id?',
        name: 'BlogCategory',
        component: () => import('../views/BlogCategoryPage/BlogCategoryPage')
    },
    {
        path: '/blog/:id',
        name: 'BlogSinglePost',
        component: () => import('../views/BlogSinglePostPage/BlogSinglePostPage')
    },
    {
        path: getPath('SingleVendor'),
        name: 'SingleVendor',
        component: () => import('../views/SingleVendorPage/SingleVendorPage')
    },
    {
        path: getPath('Contacts'),
        name: 'Contacts',
        component: () => import('../views/ContactPage/ContactPage')
    },
    {
        path: getPath('FavoriteProducts'),
        name: 'FavoriteProducts',
        component: () => import('../views/FavoritesPage/Favorites')
    },
    {
        path: getPath('Cart'),
        name: 'Cart',
        component: () => import('../views/Cart/Cart')
    },
    {
        path: getPath('SingleProductPage'),
        name: 'SingleProductPage',
        component: () => import('../views/SingleProductPage/SingleProductPage')
    },
    {
        path: getPath('MarketplaceCategoryPage'),
        name: 'MarketplaceCategoryPage',
        component: () => import('../views/MarketplaceCategoryPage/MarketpaceCategoryPage')
    },
    {
        path: getPath('AddRoommate'),
        name: 'AddRoommate',
        component: () => import('../views/AddRoommateOffer/AddRoommateOffer')
    },
    {
        path: getPath('Agency'),
        name: 'Agency',
        component: () => import('../views/SingleAgencyPage/SingleAgencyPage')
    },
    {
        path: getPath('User'),
        name: 'User',
        component: () => import('../views/SingleAgencyPage/SingleAgencyPage')
    },
    {
        path: getPath('SingleRoommateOffer'),
        name: 'SingleRoommateOffer',
        component: () => import('../views/SingleRoommateOffer/SingleRoommateOffer.vue')
    },
    {
        path: '/',
        name: 'Index',
        component: () => import('../views/Index/Index.vue')
    },
    {
        path: getPath('Marketplace'),
        name: 'Marketplace',
        component: () => import('../views/Index/Index.vue')
    },
    {
        path: getPath('PropertySearchPage'),
        name: 'PropertySearchPage',
        component: () => import('../views/SearchResult/SearchResult.vue'),
        meta: {showFooter: false}
    },
    {
        path: getPath('SearchRoommateResult'),
        name: 'SearchRoommateResult',
        component: () => import('../views/SearchRoommateResult/SearchRoommateResult.vue')
    },
    {
        path: getPath('SinglePropertyPage'),
        name: 'SinglePropertyPage',
        component: () => import('../views/SinglePropertyPage/SingplePropertyPage.vue')
    },
    {
        path: '/user/confirmation',
        name: 'UserMailConfirmation',
        component: () => import('../views/UserMailConfirmation.vue')
    },
    {
        path: '/payment/status',
        name: 'PaymentStatus',
        component: () => import('../views/PaymentStatus.vue'),
        meta: {showFooter: false}
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFoundPage',
        component: () => import('../views/NotFound.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    },
    routes: routes
})

router.beforeEach((to, from, next) => {
    store.dispatch("utils/closeMarketplaceRoomsModal");
    next();
})


export default router
