import {t, createI18n} from "vue-i18n";
import axios from "axios";
import {get} from 'lodash';
import enSystemTranslations from './en'

// List of all locales.
export const allLocales = ["bg", "en", "ro"];
const loadedLanguages = ["bg"]; // our default language that is preloaded

// Create Vue I18n instance.
export const i18n = createI18n({
    // legacy: false,
    globalInjection: true,
    // allowComposition: true,
    locale: "bg",
    fallbackLocale: "bg",
    // useScope: "global",
    messageResolver: (obj, path) => get(obj, path, '')?.replaceAll(/@/g, "{'@'}") || null,
});

// Set new locale.
export const setLang = async (lang) => {
    i18n.locale = lang;
    i18n.global.locale = lang;
    axios.defaults.headers.common["X-Accept-Language"] = lang;
    document.querySelector("html").setAttribute("lang", lang);
    window.localStorage.setItem('defaultLang', lang);
    loadLocale(lang);
}

// Fetch locale.
export function loadLocale(lang) {
    // If the same language
    // if (i18n.locale === lang) {
    //   return Promise.resolve(setLang(lang));
    // }
    //
    // // If the language was already loaded
    // if (loadedLanguages.includes(lang)) {
    //   return Promise.resolve(setLang(lang));
    // }

    const injectedTranslations = window.translations ?? null;
    if (injectedTranslations) {
        i18n.global.setLocaleMessage(lang, injectedTranslations)
        loadedLanguages.push(lang);
        return;
    }

    axios.get('/api/localization/translation/' + lang).then((response) => {
        const translations = {...enSystemTranslations, ...response.data.translations}
        i18n.global.setLocaleMessage(lang, translations)
        loadedLanguages.push(lang);
    });
}