<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<style lang="scss">
 .service-menu-item {
   &:hover {
     svg path {
       stroke: #FFFFFF;
     }
   }
 }
</style>
<template>
  <div class="d-none d-md-block header-desktop">
    <div class="header-top">
      <div class="d-flex container">
        <div class="header-top-buttons d-flex align-items-center">
          <div class="pe-2" @click="activateTranslationsDebugMode" v-if="isDevMode">[Translations]</div>
          <LangFlagGb v-if="$root.$i18n.locale !== 'en'"/>
          <a href="https://theroommates.bg" v-if="$root.$i18n.locale !== 'en'" class="ms-1 cursor-pointer">
            English
          </a>
          <LangFlagBg :to="'https://sakvartiranteite.com'" v-if="$root.$i18n.locale !== 'bg'"/>
          <a href="https://sakvartirantite.com" v-if="$root.$i18n.locale !== 'bg'" class="ms-1 cursor-pointer">
            Български
          </a>
        </div>
        <div class="header-top-buttons me-auto d-flex align-items-center">
          <TrustedAgenciesIcon/>
          <router-link :to="{name: 'TrustedAgenciesPage'}">
            <div class="ms-1">
              {{ $t('trusted-agencies') }}
            </div>
          </router-link>
        </div>
        <div class="header-top-buttons">
          <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_40_5104)">
              <path
                  d="M6.4949 13C2.90414 13 0 10.0857 0 6.4949C0 2.90414 2.91431 0 6.50507 0C10.1009 0 13.0101 2.9194 13 6.52033C12.9949 10.1009 10.0857 13.0051 6.4949 13ZM4.95891 7.13575C3.48904 6.07276 3.46869 4.196 4.41979 3.09233C5.4014 1.95813 7.10523 1.80555 8.24451 2.76682C8.85992 3.2856 9.20068 3.95696 9.22612 4.76055C9.25663 5.75234 8.83957 6.53559 8.04106 7.13066C9.48551 7.71556 10.4112 8.74803 10.8333 10.2332C12.5626 8.33606 12.9288 4.88262 10.5943 2.482C8.31062 0.137324 4.48591 0.198356 2.27347 2.62441C0.0406885 5.07589 0.523864 8.44795 2.16666 10.2332C2.58881 8.74294 3.51955 7.72064 4.95891 7.13575ZM6.69834 12.2422C6.8967 12.2167 7.29341 12.1862 7.68504 12.1099C8.58527 11.9319 9.39396 11.5352 10.106 10.9605C10.1365 10.935 10.1721 10.8791 10.167 10.8435C10.0704 10.1263 9.80084 9.48042 9.31766 8.9413C8.29537 7.80202 7.02385 7.40022 5.53872 7.76642C4.13497 8.11227 3.04655 9.35327 2.83802 10.7926C2.82785 10.8638 2.82785 10.9096 2.89397 10.9656C3.95187 11.7997 5.15218 12.2218 6.69834 12.2422ZM6.50507 6.84075C7.59858 6.83567 8.48864 5.94052 8.47846 4.86228C8.46829 3.77386 7.57315 2.88888 6.48981 2.89397C5.41157 2.89906 4.52659 3.7942 4.52659 4.86736C4.52659 5.95578 5.42174 6.84584 6.50507 6.84075Z"
                  fill="#434D56"/>
            </g>
            <defs>
              <clipPath id="clip0_40_5104">
                <rect width="13" height="13" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <router-link v-if="isUserAuthenticated"
                       :to="{'name': $store.state.user.info.type === 'vendor' ? 'VendorDashboard' : 'AgencyDashboard'}"
                       class="orange-hover ps-1">
            {{ $t('profile') }}
          </router-link>
          <span v-else class="ps-1 cursor-pointer" @click="$store.dispatch('utils/openModal', 'login')">
            {{ $t('login') }}
          </span>
        </div>
        <div class="header-top-buttons">
          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_40_5105)">
              <path
                  d="M6.50744 1.25129C6.81248 0.918252 7.15312 0.649675 7.53442 0.440186C9.74091 -0.768409 12.466 0.638932 12.9337 3.2119C13.1117 4.20563 12.9185 5.1349 12.5117 6.02658C12.0847 6.95585 11.4848 7.75621 10.8137 8.50285C9.62906 9.81888 8.2767 10.9147 6.83282 11.8923C6.60912 12.0427 6.41592 12.0481 6.19222 11.8923C4.62633 10.8395 3.17736 9.64699 1.93176 8.19131C1.23524 7.3802 0.630237 6.49927 0.274351 5.4572C-0.213721 4.02837 -0.056114 2.68549 0.843769 1.48226C1.47928 0.622818 2.33849 0.13938 3.37056 0.0265777C4.57549 -0.102339 5.60755 0.31664 6.46168 1.21369C6.47693 1.22443 6.4871 1.2298 6.50744 1.25129ZM0.818348 4.11968C0.818348 4.32917 0.879357 4.68369 0.986123 5.03284C1.26066 5.92452 1.75382 6.67653 2.32832 7.37483C3.51291 8.82515 4.93137 9.9854 6.43626 11.0328C6.49218 11.0704 6.52777 11.0651 6.57861 11.0328C7.87506 10.1304 9.1054 9.13132 10.1883 7.94421C10.7933 7.27814 11.3424 6.5691 11.739 5.73652C12.0847 5.01136 12.2779 4.25397 12.1406 3.42676C11.9118 2.02479 10.7577 0.923623 9.41045 0.864537C8.35296 0.816193 7.49375 1.25129 6.85316 2.14833C6.64471 2.4384 6.34983 2.4384 6.1363 2.14833C5.40419 1.14923 4.4433 0.719505 3.25362 0.91288C1.86059 1.14386 0.813264 2.45988 0.818348 4.11968Z"
                  fill="#434D56"/>
            </g>
            <defs>
              <clipPath id="clip0_40_5105">
                <rect width="13" height="12" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <router-link :to="{ name: 'FavoriteProducts' }" class="ps-1 orange-hover">{{ $t('favorites') }}</router-link>
        </div>
        <div @click="openCart()" class="header-top-buttons">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.42" y="4.42" width="8.16" height="7.16" rx="0.58" stroke="#FF7A00" stroke-width="0.84"/>
            <path d="M9 6V3.5C9 2.11929 7.88071 1 6.5 1V1C5.11929 1 4 2.11929 4 3.5V6" stroke="#FF7A00"
                  stroke-width="0.84" stroke-linecap="round"/>
          </svg>
          <span class="ps-1 cursor-pointer">{{ $t('cart') }} ({{ ($store.state.cart.totalProductCount) }})</span>
        </div>
      </div>
    </div>
    <div class="header-bottom ">
      <div class="container d-flex">
        <div class="me-auto">
          <router-link :to="{ name: 'Index' }">
              <div class="logo-desktop">
                  <Logo :lang="$root.$i18n.locale"/>
              </div>

          </router-link>
        </div>
        <div class="header-bottom-buttons service-item">
          <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 16.9027V8.90268L6.5 4.90268L12 9.06927V16.9027H1Z" stroke="#FF7A00" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.7378 9.3819V4.03017L19.4171 1.35431L23.0964 4.14161V9.3819H15.7378Z" stroke="#FF7A00"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.87189 20.6457H13.4307C19.1532 20.6457 18.4136 18.1934 18.4136 13.1597" stroke="#FF7A00"
                  stroke-width="2" stroke-linecap="round"/>
            <path d="M15.7378 14.977L18.3729 13.1597L20.8138 15.0396" stroke="#FF7A00" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <router-link :to="{ name: 'MovingPage' }" class="ps-1 orange-hover">{{ $t('moving-services') }}</router-link>
        </div>
        <div class="header-bottom-buttons service-item">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.9783 9.22084L18.0105 10.0357L16.0398 10.8336C14.1089 11.6153 12.5592 13.1194 11.72 15.0259L10.5256 17.7392L9.44863 15.1975C8.58535 13.1602 6.91681 11.5714 4.83967 10.8088L2.84375 10.0761L5.03769 9.21257C6.98239 8.44717 8.55036 6.95117 9.40622 5.04455L10.5256 2.55081L11.6572 5.07168C12.5058 6.96217 14.0549 8.44962 15.9783 9.22084Z"
                stroke="#FF7A00" stroke-width="2"/>
          </svg>
          <router-link :to="{ name: 'CleaningPage' }" class="ps-1 orange-hover">{{
              $t('cleaning-services')
            }}
          </router-link>
        </div>
        <div class="header-bottom-buttons service-item">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 14.165H1V7.97939M12.3077 14.165H15V1.79382H9.75M1 7.97939V1.79382H9.75M1 7.97939H5.66667M5.66667 7.97939V11.3533M5.66667 7.97939V5.16777M9.75 1.79382V7.97939H11.5"
                stroke="#FF7A00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <router-link :to="{ name: 'InteriorPage' }" class="ps-1 orange-hover">{{ $t('interior') }}</router-link>
        </div>
          <div class="header-bottom-buttons service-item">

          </div>
          <div class="header-bottom-buttons services-button" @click="isServiceMenuDropdownOpen = !isServiceMenuDropdownOpen" >
              <div class="p-1">
                  <i class="arrow-down"></i>
              </div>
              <div class="ps-1 orange-hover">{{
                      $t('services')
                  }}
              </div>
          </div>
        <div class="header-bottom-buttons position-relative">
            <div class="sort-filter-modal" v-if="isServiceMenuDropdownOpen">
                <div class="sort-item service-menu-item" v-for="(option, index) in serviceMenuItems" @click="redirectTo(option.routeName)" @key="index + '-svc'">
                    <div class="d-flex align-items-center orange-hover" >
                            <House />
                           <div class="ms-2">{{ $t(option.title) }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-self-center me-2">
          <router-link :to="{ name: 'SearchRoommateResult' }">
            <Button @click="$router.push({ name: 'SearchRoommateResult' })" :size="'header'"
                    :theme="'outline-orange'" :text="$t('search-roommate')" :svg="'search'"/>
          </router-link>
        </div>
        <div class="d-flex align-self-center me-2">
          <router-link :to="{ name: 'PropertySearchPage' }" class="">
            <Button :svg="'house'" :size="'header'" :theme="'outline-orange'" :text="$t('properties-for-rent')"/>
          </router-link>
        </div>
        <div class="d-flex align-self-center me-2" v-if="isUserAuthenticated">
          <router-link :to="{ name: 'AddProperty' }" v-if="user.type !== 'vendor'">
            <Button :size="'header'" :theme="'outline-orange'" :text="$t('add-property')" :svg="'plus'"/>
          </router-link>
          <router-link :to="{ name: 'AdminAddProduct' }" v-else>
            <Button :size="'header'" :theme="'outline-orange'" :text="$t('add-product')" :svg="'plus'"/>
          </router-link>
        </div>
        <div class="d-flex align-self-center me-2" v-else>
          <router-link to="#" v-if="user.type !== 'vendor'">
            <Button @click="$store.dispatch('utils/openModal', 'login')" :size="'header'" :theme="'outline-orange'"
                    :text="$t('add-property')" :svg="'plus'"/>
          </router-link>
          <router-link to="#" v-else>
            <Button @click="$store.dispatch('utils/openModal', 'login')" :size="'header'" :theme="'outline-orange'"
                    :text="$t('add-product')" :svg="'plus'"/>
          </router-link>
        </div>
        <div class="d-flex align-self-center" @click="$store.dispatch('utils/toggleMarketplaceRoomsModal')">
          <div>
            <Button :size="'header'" :theme="'solid-orange'" :text="$t('shop')" :svg="'shop'"/>
          </div>
        </div>
      </div>
    </div>
    <div class="progress position-absolute" v-if="$store.state.utils.progressBar !== null">
      <div ref="progressbar"
           class="progress-bar"
           role="progressbar"
           v-bind:style="{width: $store.state.utils.progressBar + '%'}"
           aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
  <div v-bind:class="{ 'open-mobile-menu': $store.state.utils.modals.mobileMenu }">
    <div class="d-md-none header-mobile">
      <div class="d-flex align-items-center">
        <div class="header-logo me-auto">
          <router-link :to="{ name: 'Index' }">
            <LogoMobile :lang="$root.$i18n.locale"/>
          </router-link>
        </div>

        <router-link v-if="isUserAuthenticated"
                     :to="{'name': user.type === 'vendor' ? 'VendorDashboard' : 'AgencyDashboard'}" class="ps-1">
          <div class="header-mobile-buttons">
            <ProfileIcon/>
          </div>

        </router-link>

        <div class="header-mobile-buttons" @click="$store.dispatch('utils/openModal', 'login')" v-else>
          <ProfileIcon/>
        </div>

        <router-link :to="{ name: 'FavoriteProducts' }">
          <div class="header-mobile-buttons">
            <svg width="23.5" height="20" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_40_5105)">
                <path
                    d="M6.50744 1.25129C6.81248 0.918252 7.15312 0.649675 7.53442 0.440186C9.74091 -0.768409 12.466 0.638932 12.9337 3.2119C13.1117 4.20563 12.9185 5.1349 12.5117 6.02658C12.0847 6.95585 11.4848 7.75621 10.8137 8.50285C9.62906 9.81888 8.2767 10.9147 6.83282 11.8923C6.60912 12.0427 6.41592 12.0481 6.19222 11.8923C4.62633 10.8395 3.17736 9.64699 1.93176 8.19131C1.23524 7.3802 0.630237 6.49927 0.274351 5.4572C-0.213721 4.02837 -0.056114 2.68549 0.843769 1.48226C1.47928 0.622818 2.33849 0.13938 3.37056 0.0265777C4.57549 -0.102339 5.60755 0.31664 6.46168 1.21369C6.47693 1.22443 6.4871 1.2298 6.50744 1.25129ZM0.818348 4.11968C0.818348 4.32917 0.879357 4.68369 0.986123 5.03284C1.26066 5.92452 1.75382 6.67653 2.32832 7.37483C3.51291 8.82515 4.93137 9.9854 6.43626 11.0328C6.49218 11.0704 6.52777 11.0651 6.57861 11.0328C7.87506 10.1304 9.1054 9.13132 10.1883 7.94421C10.7933 7.27814 11.3424 6.5691 11.739 5.73652C12.0847 5.01136 12.2779 4.25397 12.1406 3.42676C11.9118 2.02479 10.7577 0.923623 9.41045 0.864537C8.35296 0.816193 7.49375 1.25129 6.85316 2.14833C6.64471 2.4384 6.34983 2.4384 6.1363 2.14833C5.40419 1.14923 4.4433 0.719505 3.25362 0.91288C1.86059 1.14386 0.813264 2.45988 0.818348 4.11968Z"
                    fill="#434D56"/>
              </g>
              <defs>
                <clipPath id="clip0_40_5105">
                  <rect width="13" height="12" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        </router-link>
        <router-link :to="{ name: 'Cart' }">
          <div class="header-mobile-buttons">
            <svg width="23.5" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2.42" y="4.42" width="8.16" height="7.16" rx="0.58" stroke="#FF7A00" stroke-width="0.84"/>
              <path d="M9 6V3.5C9 2.11929 7.88071 1 6.5 1V1C5.11929 1 4 2.11929 4 3.5V6" stroke="#FF7A00"
                    stroke-width="0.84" stroke-linecap="round"/>
            </svg>
          </div>
        </router-link>
        <div class="header-mobile-buttons hamburger" v-on:click="toggleMobileMenu">
          <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1.43492" y1="1.11586" x2="28.9422" y2="1.11586" stroke="#FF7A00" stroke-width="2.11594"
                  stroke-linecap="round"/>
            <line x1="1.43492" y1="9.57972" x2="28.9422" y2="9.57972" stroke="#FF7A00" stroke-width="2.11594"
                  stroke-linecap="round"/>
            <line x1="1.43492" y1="18.0436" x2="28.9422" y2="18.0436" stroke="#FF7A00" stroke-width="2.11594"
                  stroke-linecap="round"/>
          </svg>
        </div>
        <div class="header-mobile-buttons x-icon" v-on:click="toggleMobileMenu">
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="2" y1="20.6146" x2="21.4506" y2="1.16408" stroke="#FF7A00" stroke-width="2.11594"
                  stroke-linecap="round"/>
            <line x1="21.7352" y1="20.9468" x2="2.28469" y2="1.49622" stroke="#FF7A00" stroke-width="2.11594"
                  stroke-linecap="round"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="d-md-none mobile-menu" v-if="$store.state.utils.modals.mobileMenu">
      <UserTypeMobile/>
      <UserNavMobile v-if="this.isUserAuthenticated" :usertype="'regular'"/>
      <div class="mobile-header-buttons ">
        <div class="header-top-buttons d-flex align-items-center mt-4 mb-3">
          <div class="pe-2" @click="activateTranslationsDebugMode" v-if="isDevMode">[Translations]</div>
          <LangFlagGb v-if="$root.$i18n.locale !== 'en'"/>
          <a href="https://theroommates.bg" v-if="$root.$i18n.locale !== 'en'" class="ms-1 cursor-pointer">
            English
          </a>
          <LangFlagBg :to="'https://sakvartiranteite.com'" v-if="$root.$i18n.locale !== 'bg'"/>
          <a href="https://sakvartirantite.com" v-if="$root.$i18n.locale !== 'bg'" class="ms-1 cursor-pointer">
            Български
          </a>        </div>
        <div class="mt-3 mb-3">
          <router-link :to="{name: 'TrustedAgenciesPage'}">
            <div class="header-top-buttons mt-3 d-flex align-items-center">
              <TrustedAgenciesIcon/>
              <div class="ms-1">
                {{ $t('trusted-agencies') }}
              </div>
            </div>
          </router-link>
        </div>
        <div class="d-flex justify-content-center  ">
          <div class="col-12 container">
            <div class="mt-2 mb-2">
              <router-link :to="{ name: 'SearchRoommateResult' }">
                <Button @click="$router.push({ name: 'SearchRoommateResult' })" :size="'medium'"
                        :theme="'outline-orange'" :text="$t('search-roommate')" :svg="'search'"/>
              </router-link>
            </div>
            <div class="mt-2 mb-2">
              <router-link :to="{ name: 'PropertySearchPage' }" class="">
                <Button :svg="'house'" :size="'medium'" :theme="'outline-orange'" :text="$t('properties-for-rent')"/>
              </router-link>
            </div>
            <div class="mt-2 mb-2" v-if="user.type !== 'vendor'">
                <Button :size="'medium'" :theme="'outline-orange'" :text="$t('add-property')" :svg="'plus'"  @click="isUserAuthenticated  ? $router.push({name: 'AddProperty'}): openLoginModal()"/>
            </div>
            <div class="mt-2 mb-2" v-else>
              <router-link :to="{ name: 'AdminAddProduct' }">
                <Button :size="'medium'" :theme="'outline-orange'" :text="$t('add-product')" :svg="'plus'"/>
              </router-link>
            </div>

          </div>

        </div>
        <div class="container">

          <div class="mobile-buttons">
            <div class="mobile-svg-container">
              <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.4495 20.3164H1.18158V10.6582M19.3836 20.3164H23.7174V1H15.2665M1.18158 10.6582V1H15.2665M1.18158 10.6582H8.69352M8.69352 10.6582V15.9263M8.69352 10.6582V6.26811M15.2665 1V10.6582H18.0834"
                    stroke="#FF7A00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>


            <router-link :to="{ name: 'InteriorPage' }" class="ps-3 orange-hover">{{ $t('interior') }}</router-link>
          </div>
          <div class="mobile-buttons">
            <div class="mobile-svg-container">
              <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.56714 16.5488V8.54883L7.06714 4.54883L12.5671 8.71542V16.5488H1.56714Z" stroke="#FF7A00"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.3049 9.02759V3.67586L19.9842 1L23.6636 3.7873V9.02759H16.3049Z" stroke="#FF7A00"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.4392 20.2917H13.998C19.7205 20.2917 18.9809 17.8394 18.9809 12.8057" stroke="#FF7A00"
                      stroke-width="2" stroke-linecap="round"/>
              </svg>
            </div>
            <router-link :to="{ name: 'MovingPage' }" class="ps-3 orange-hover">{{
                $t('moving-services')
              }}
            </router-link>
          </div>
          <div class="mobile-buttons">
            <div class="mobile-svg-container">
              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.8645 11.5906L23.6636 13.1139L19.9431 14.6202C18.0122 15.402 16.4625 16.906 15.6233 18.8125L13.5498 23.5232L11.6192 18.9671C10.7559 16.9297 9.08736 15.3409 7.01022 14.5784L3.16968 13.1684L7.19971 11.5823C9.1444 10.8169 10.7124 9.32089 11.5682 7.41427L13.5498 3L15.5434 7.44139C16.3921 9.33189 17.9412 10.8193 19.8645 11.5906Z"
                    stroke="#FF7A00" stroke-width="2"/>
              </svg>
            </div>
            <router-link :to="{ name: 'CleaningPage' }" class="ps-3 orange-hover">{{
                $t('cleaning-services')
              }}
            </router-link>
          </div>
        </div>
      </div>
      <RoomsNavMobile/>
      <BlogContactsNavMobile/>
      <PolicyNavMobile/>

    </div>
  </div>

  <SignUpModal/>
  <LoginModal/>
  <NotificationModal/>
  <PassChangeModal/>
  <ForgottenPassModal/>
  <PropertyViewRequestModal/>
  <RoommateOfferMessageModal/>
  <CreditBuyModal/>
  <CoordinatesGuideModal/>
</template>
