export default {
    "2-ри корпус": "2nd Corps",
    "5-ти полк": "5th Regiment",
    "7-и – 11-и километър": "7th – 11th kilometer",
    "7-ми 11-ти километър": "7th-11th Kilometer",
    "9-ти квартал": "9th Quarter",
    "Абдовица": "Abdovitsa",
    "Абитрус": "Abitrus",
    "Аврен": "Avren",
    "Автогара": "Bus Station",
    "Аерогарата": "Airport",
    "Акациите": "Akatsiite",
    "Акация": "Acacia",
    "Акджамия": "Akdzhamia",
    "Алеи Възраждане": "Alei Vazrazhdane",
    "Александър Стамболийски": "Alexander Stamboliyski",
    "Ален мак": "Alen Mak",
    "Альоша": "Alyosha",
    "Американски колеж (вилна зона)": "American College (villa zone)",
    "Асенов": "Asenov",
    "Асеновградско шосе": "Asenovgrad Road",
    "Аспарухово": "Asparuhovo",
    "Атюрен": "Atyuren",
    "Аязмо": "Ayazmo",
    "Аязмото": "Ayazmoto",
    "БАН IV километър": "BAN IV Kilometer",
    "Баба Тонка": "Baba Tonka",
    "Бадема": "Badema",
    "Базар Левски": "Levski Bazaar",
    "Байкал": "Baikal",
    "Байкушева махала": "Baikusheva Mahala",
    "Баларбаши": "Balarbashi",
    "Балик": "Balik",
    "Банево": "Banevo",
    "Банишора": "Banishora",
    "Барите": "Barite",
    "Басарбово": "Basarbovo",
    "Басейна": "Baseina",
    "Батареята": "Battery",
    "Бела вода": "Bela Voda",
    "Беленци": "Belentsi",
    "Бели Лом": "Beli Lom",
    "Бели брег": "Beli Breg",
    "Белите брези": "White Birches",
    "Беломорски": "Belomorski",
    "Бенковски": "Benkovski",
    "Бизнес парк Варна": "Business Park Varna",
    "Бизнес хотел": "Business Hotel",
    "Била": "Billa",
    "Бистрец": "Bistrets",
    "Бичкиня": "Bichkinya",
    "Благоевград": "Blagoevgrad",
    "Бойката": "Boykata",
    "Болницата": "Bolnitsata",
    "Болта": "Bolta",
    "Болярово": "Bolyarovo",
    "Бонония": "Bononia",
    "Боровец": "Borovets",
    "Борово": "Borovo",
    "Ботунец": "Botunets",
    "Ботунец 1": "Botunets 1",
    "Ботунец 2": "Botunets 2",
    "Боян Българанов 1": "Boyan Bulgaranov 1",
    "Боян Българанов 2": "Boyan Bulgaranov 2",
    "Бояна": "Boyana",
    "Братя Миладинове": "Bratya Miladinovi",
    "Братя Миладинови": "Bratya Miladinovi",
    "Брезовско шосе": "Brezovsko Road",
    "Бриз": "Briz",
    "Бряг": "Bryag",
    "Бузлуджа": "Buzludzha",
    "Бункера": "Bunkera",
    "Бургас": "Burgas",
    "Бъкстон": "Buxton",
    "Българка": "Bulgarka",
    "Бял камък": "Byal Kamak",
    "Бялата висота": "Byalata Visota",
    "Бялата пръст": "Byalata Prast",
    "ВИНС-Червен площад": "VINS-Red Square",
    "Варна": "Varna",
    "Варош": "Varosh",
    "Вароша": "Varosha",
    "Варуша": "Varusha",
    "Варуша-юг": "Varusha South",
    "Варчевци": "Varchevtsi",
    "Васил Левски": "Vasil Levski",
    "Веждата": "Vezhdata",
    "Вежен": "Vejen",
    "Велико Търново": "Veliko Tarnovo",
    "Велчевци": "Velchevtsi",
    "Веселчане": "Veselchane",
    "Ветрен": "Vetren",
    "Вида 1": "Vida 1",
    "Вида 2": "Vida 2",
    "Видин": "Vidin",
    "Вилна зона": "Villa Zone",
    "Виница": "Vinitsa",
    "Витоша": "Vitosha",
    "Владислав Варненчик": "Vladislav Varnenchik",
    "Владислав Варненчик 1": "Vladislav Varnenchik 1",
    "Владислав Варненчик 2": "Vladislav Varnenchik 2",
    "Владиславово": "Vladislavovo",
    "Воденицата": "Vodenitsata",
    "Воеводски": "Voevodski",
    "Военен полигон Боровец": "Military Range Borovets",
    "Военна база": "Military Base",
    "Военна рампа": "Voenna Rampa",
    "Военно училище": "Military School",
    "Войново": "Voinovo",
    "Враждебна": "Vrazhdebna",
    "Враца": "Vratsa",
    "Връбница 1": "Vrabnitsa 1",
    "Връбница 2": "Vrabnitsa 2",
    "Връбница-1": "Vrabnitsa-1",
    "Връбница-2": "Vrabnitsa-2",
    "Втора промишлена зона": "Second Industrial Zone",
    "Въбел": "Vabel",
    "Възраждане": "Vuzrazhdane",
    "Възраждане 1": "Vazrazhdane 1",
    "Възраждане 2": "Vazrazhdane 2",
    "Възраждане 3": "Vazrazhdane 3",
    "Възраждане 4": "Vazrazhdane 4",
    "Възрожденци": "Vazrozhdentsi",
    "Върташево": "Vartashevo",
    "Въстанически": "Vustanicheski",
    "Габрово": "Gabrovo",
    "Гагарин": "Gagarin",

    "Галата": "Galata",

    "Гачевци": "Gachevtsi",

    "Гевгелийски": "Gevgeliyski",

    "Гевгелийски квартал": "Gevgeliski District",

    "Генерал Заимов": "General Zaimov",

    "Гео Милев": "Geo Milev",

    "Георги Бенковски": "Georgi Benkovski",

    "Герена": "Gerena",

    "Гладно поле": "Gladno Pole",

    "Гледка": "Gledka",

    "Гозница": "Goznitsa",

    "Голямоконарско шосе": "Golyamokonarsko Road",

    "Горна Гледка": "Gorna Gledka",

    "Горна баня": "Gorna Banya",

    "Горна баня (вилна зона)": "Gorna Banya (villa zone)",

    "Горни Бакойци": "Gorni Bakoytsi",

    "Горно Влахово": "Gorno Vlakhovo",

    "Горно Езерово": "Gorno Ezerovo",

    "Горно Райково": "Gorno Raikovo",

    "Горубляне": "Gorublyane",

    "Гоце Делчев": "Gotse Delchev",

    "Градина": "Gradina",

    "Градска болница": "City Hospital",

    "Грамада": "Gramada",

    "Гранд Мол": "Grand Mall",

    "Граф Игнатиев": "Graf Ignatiev",

    "Гривица": "Grivitsa",

    "Гроздов пазар": "Grozdov Pazar",

    "Група-Зоопарк": "Zoo Group",

    "Гръцка махала": "Greek Mahala",

    "Гръцки квартал": "Greek Quarter",

    "Гуджуците": "Gudzhutsite",

    "Гърдова глава": "Gurdova Glava",

    "Д-р Дончев": "Dr. Donchev",

    "ДЗС": "DZS",

    "Даме Груев": "Dame Gruev",

    "Диана": "Diana",

    "Дианабад": "Dianabad",

    "Дианабад (промишлена зона)": "Dianabad (industrial zone)",

    "Дивдядово": "Divdyadovo",

    "Дивизионна болница": "Divisional Hospital",

    "Дикисана": "Dikisana",

    "Димитър Димов": "Dimitar Dimov",

    "Димитър Миленков": "Dimitar Milenkov",

    "Димова махала": "Dimova Mahala",

    "Добрич": "Dobrich",

    "Добротица - Албенска": "Dobrotitsa - Albenska",

    "Добротица - Варненска": "Dobrotitsa - Varnenska",

    "Добруджански": "Dobrudzhanski",

    "Докторски паметник": "Doctors Monument",

    "Долапите": "Dolapite",

    "Долни Бакойци": "Dolni Bakoytsi",

    "Долни Смърдан": "Dolni Smardan",

    "Долно Езерово": "Dolno Ezerovo",

    "Долно Райково": "Dolno Raikovo",

    "Драгалевци": "Dragalevtsi",

    "Драгановец": "Draganovets",

    "Дружба": "Druzhba",

    "Дружба 1": "Druzhba 1",

    "Дружба 2": "Druzhba 2",

    "Дружба 3": "Druzhba 3",

    "Дружба 4": "Druzhba 4",

    "Друмо": "Drumo",

    "Дръстене": "Drustene",

    "Дунавите": "Dunavite",

    "Дъбника": "Dubnika",

    "Дъбрава": "Dubrava",

    "Дървеница": "Durvenitsa",

    "Дядо Дянко": "Dyado Dyanko",

    "Еверест": "Everest",

    "Езерово": "Ezerovo",

    "Експериментален": "Experimental",

    "Електроразпределение Варна": "Electric Distribution Varna",

    "Еленово 1": "Elenovo 1",

    "Еленово 2": "Elenovo 2",

    "Енергоразпределение": "Energy Distribution",

    "Етъра": "Etara",

    "ЖП Гара": "Railway Station",

    "Железник": "Zheleznik",

    "Железник - изток": "Zheleznik - East",

    "Железник - център": "Zheleznik - Center",

    "Жерави": "Zheravi",

    "Жеравица": "Zheravitsa",

    "Житница": "Zhitnitsa",

    "Завод Дружба": "Druzhba Factory",

    "Завод Найден Киров": "Zavod Nayden Kirov",

    "Загорка": "Zagorka",

    "Запад": "Zapad",

    "Запад 1": "Zapad 1",

    "Запад 2": "Zapad 2",

    "Запад 3": "Zapad 3",

    "Западен": "Zapaden",

    "Западен парк": "Zapaden Park",

    "Западна дъга": "Zapadna Daga",

    "Западна промишлена зона": "Western Industrial Zone",

    "Захаринова махала": "Zaharinova Mahala",

    "Захарна фабрика": "Zaharna Fabrika",

    "Здравец": "Zdravets",

    "Здравец Изток": "Zdravets East",

    "Здравец Север 1": "Zdravets North 1",

    "Здравец Север 2": "Zdravets North 2",

    "Зимно кино Тракия": "Winter Cinema Trakia",

    "Златари": "Zlatari",

    "Златен рог": "Zlaten Rog",

    "Зона Б": "Zone B",

    "Зона Б-18": "Zona B-18",

    "Зона Б-19": "Zona B-19",

    "Зона Б-5": "Zona B-5",

    "Зона Б-5-3": "Zona B-5-3",

    "Зора": "Zora",

    "Зорница": "Zornitsa",

    "Иван Вазов": "Ivan Vazov",

    "Иглика": "Iglika",

    "Идеален център": "Ideal Center",

    "Изгрев": "Izgrev",

    "Изток": "Iztok",

    "Изток (промишлена зона)": "Iztok (industrial zone)",

    "Изток 1": "Izto 1",

    "Източна дъга": "Iztochna Daga",

    "Илевци": "Ilevtsi",

    "Илинден": "Ilinden",

    "Илиянци": "Iliyantsi",

    "Илиянци (промишлена зона)": "Iliyantsi (industrial zone)",

    "Илчова махала": "Ilchova Mahala",

    "Ильо войвода": "Ilyo Voyvoda",

    "Индустриална зона": "Industrial Zone",

    "Индустриална зона - Запад": "Industrial Zone - West",

    "Индустриална зона - Изгрев": "Industrial Zone - Izgrev",

    "Индустриална зона - Изток": "Industrial Zone - East",

    "Индустриална зона - Марица": "Industrial Zone - Maritsa",

    "Индустриална зона - Север": "Industrial Zone - North",

    "Индустриална зона - Тракия": "Industrial Zone - Trakia",

    "Индустриална зона - Юг": "Industrial Zone - South",

    "Индустриална зона - запад": "Industrial Zone - West",

    "Индустриална зона - изток": "Industrial Zone - East",

    "Институт по овощарство": "Institute of Horticulture",

    "Искър (промишлена зона)": "Iskar (industrial zone)",

    "Йовково": "Yovkovo",

    "Йовчевци": "Yovchevtsi",

    "КТМ": "KTM",

    "Казански": "Kazanski",

    "Казански - изток": "Kazanski - East",

    "Кайлъка": "Kailaka",

    "Кайсиева градина": "Kaisieva Gradina",

    "Калето": "Kaleto",

    "Калкас": "Kalkas",

    "Калково": "Kalkovo",

    "Каменина": "Kamenina",

    "Каменица": "Kamenitsa",

    "Каменица 1": "Kamenitsa 1",

    "Каменица 2": "Kamenitsa 2",

    "Каменни": "Kameni",

    "Канала": "Kanala",

    "Капана": "Kapana",

    "Каптажа": "Kaptazha",

    "Каргон": "Kargon",

    "Карловско шосе": "Karlovsko Road",

    "Карпузица": "Karpuzitsa",

    "Картала": "Kartala",

    "Католишка махала": "Katolichka Mahala",

    "Квартал 103": "Quarter 103",

    "Квартал 72": "Quarter 72",

    "Кемера - Художник": "Kemera - Hudojnik",

    "Киевци": "Kievtsi",

    "Килиите": "Kiliite",

    "Киноцентъра III": "Kinotsentura III",

    "Клепало": "Klepalo",

    "Клокотница": "Klokotnitsa",

    "Клуцохор": "Klutsokhor",

    "Княжево": "Knyazhevo",

    "Колуша": "Kolusha",

    "Колхозен пазар": "Kolhozen Pazar",

    "Кольо Ганчев": "Kolyu Ganchev",

    "Колю Ганчев": "Kolyu Ganchev",

    "Колю Фичето": "Kolyu Ficheto",

    "Коматево": "Komatevo",

    "Коматевски възел": "Komatevski Junction",

    "Коматевско шосе": "Komatevsko Road",

    "Комлука": "Komluka",

    "Конфуто": "Konfuto",

    "Кошаревска махала": "Koshareska Mahala",

    "Кошарник": "Kosharnik",

    "Крайморие": "Kraymorie",

    "Кралска зона": "Royal Zone",

    "Красна поляна": "Krasna Polyana",

    "Красна поляна 1": "Krasna Polyana 1",

    "Красна поляна 2": "Krasna Polyana 2",

    "Красна поляна 3": "Krasna Polyana 3",

    "Красно село": "Krasno Selo",

    "Кремиковци": "Kremikovtsi",

    "Крива река": "Kriva Reka",

    "Кристал": "Kristal",

    "Крум Бъчваров": "Krum Bachvarov",

    "Кръстова вада": "Krustova Vada",

    "Кряковци": "Kryakovtsi",

    "Куба": "Kuba",

    "Кукленско шосе": "Kuklensko Road",

    "Кулата": "Kulata",

    "Кумарица": "Kumaritsa",

    "Кумбаир": "Kumbair",

    "Куршун чешма": "Kurshun Cheshma",

    "Куциян": "Kutsiyan",

    "Кърджали": "Kardzhali",

    "Къро": "Kuro",

    "Кършияка": "Karshiyaka",

    "Кюстендил": "Kyustendil",

    "Кючук Париж": "Kyuchuk Parizh",

    "Лагера": "Lagera",

    "Ладовица": "Ladovitsa",

    "Лазур": "Lazur",

    "Лаута": "Lauta",

    "Лев Толстой": "Lev Tolstoy",

    "Левски": "Levski",

    "Левски 1": "Levski 1",

    "Левски 2": "Levski 2",

    "Левски В": "Levski V",

    "Левски Г": "Levski G",

    "Левски Зона Б": "Levski Zone B",

    "Летище": "Airport",

    "Летище Бургас": "Burgas Airport",

    "Лисец": "Lisets",

    "Ловеч": "Lovech",

    "Лозенец": "Lozenets",

    "Лозово": "Lozovo",

    "Лудогорие": "Ludogorie",

    "Любен Каравелов": "Lyuben Karavelov",

    "Люлин": "Lyulin",

    "Люлин (вилна зона)": "Lyulin (villa zone)",

    "Люлин 1": "Lyulin 1",

    "Люлин 10": "Lyulin 10",

    "Люлин 2": "Lyulin 2",

    "Люлин 3": "Lyulin 3",

    "Люлин 4": "Lyulin 4",

    "Люлин 5": "Lyulin 5",

    "Люлин 6": "Lyulin 6",

    "Люлин 7": "Lyulin 7",

    "Люлин 8": "Lyulin 8",

    "Люлин 9": "Lyulin 9",

    "Лютаци": "Lyutatsi",

    "Лятно кино Тракия": "Summer Cinema Trakia",

    "МБАЛ Иван Рилски": "MBAL Ivan Rilski",

    "Макак": "Macak",

    "Македонски": "Makedonski",

    "Максуда": "Maksuda",

    "Мала Кутловица": "Mala Kutlovitsa",

    "Мала кория": "Mala Koria",

    "Малашевци": "Malashevtsi",

    "Малинова долина": "Malinova Dolina",

    "Малка Чайка": "Little Chaika",

    "Малчика": "Malchika",

    "Малчо Малчев": "Malcho Malchev",

    "Мальовица": "Malyovitsa",

    "Манастирски ливади": "Manastirski Livadi",

    "Манастирски ливади - Б": "Manastirski Livadi - B",

    "Мара Денчева": "Mara Dencheva",

    "Мараша": "Marasha",

    "Марино поле": "Marino Pole",

    "Математическа гимназия": "Mathematical High School",

    "Махала Барселона": "Mahala Barcelona",

    "Мацова махала": "Matsova Mahala",

    "Мджиди Табия": "Mdzidi Tabiya",

    "Меден Рудник": "Meden Rudnik",

    "Меден рудник - зона А": "Meden Rudnik - Zone A",

    "Меден рудник - зона Б": "Meden Rudnik - Zone B",

    "Меден рудник - зона В": "Meden Rudnik - Zone V",

    "Меден рудник - зона Г": "Meden Rudnik - Zone G",

    "Меден рудник - зона Д": "Meden Rudnik - Zone D",

    "Медицинска Академия": "Medical Academy",

    "Медковец": "Medkovets",

    "Металург": "Metalurg",

    "Метро": "Metro",

    "Мидия Енос": "Midia Enos",

    "Митница": "Customs",

    "Младежки Хълм": "Mladezhki Hill",

    "Младежки хълм": "Mladezhki Hill",

    "Младост": "Mladost",

    "Младост 1": "Mladost 1",

    "Младост 1А": "Mladost 1A",

    "Младост 2": "Mladost 2",

    "Младост 3": "Mladost 3",

    "Младост 4": "Mladost 4",

    "Могилата": "Mogilata",

    "Могилата (вилна зона)": "Mogilata (villa zone)",

    "Могиличе": "Mogiliche",

    "Модерно предградие": "Moderno Predgradie",

    "Момина крепост": "Momina Krepost",

    "Монтана": "Montana",

    "Монте Карло": "Monte Carlo",

    "Моста на Лютата": "Mosta na Lyutata",

    "Мотописта": "Motopista",

    "Мототехника": "Mototehnika",

    "Мошино": "Moshino",

    "Мътница": "Mutnitsa",

    "Надежда": "Nadezhda",

    "Надежда 1": "Nadezhda 1",

    "Надежда 2": "Nadezhda 2",

    "Надежда 3": "Nadezhda 3",

    "Надежда 4": "Nadezhda 4",

    "Надежда I": "Nadezhda I",

    "Надежда II": "Nadezhda II",

    "Надежда III": "Nadezhda III",

    "Надежда IV": "Nadezhda IV",

    "Национален киноцентър": "National Film Center",

    "Невястата": "Nevyastata",

    "Негенци": "Negentsi",

    "Недевци": "Nedevtsi",

    "Никола Въжаров": "Nikola Vuzharov",

    "Никола Петков": "Nikola Petkov",

    "Нов Драгановец": "Nov Draganovets",

    "Нов път": "Nov Put",

    "Нов център": "New Center",

    "Нова махала – Враждебна": "Nova Mahala – Vrazhdebna",

    "Нова промишлена зона": "New Industrial Zone",

    "Новата махала": "Novata Mahala",

    "Нови силози": "Novi Silozi",

    "Ново село": "Novo Selo",

    "ОРБ": "ORB",

    "ОУ П. Р. Славейков": "Primary School P. R. Slaveykov",

    "Обеля": "Obelya",

    "Обеля 1": "Obelya 1",

    "Обеля 2": "Obelya 2",

    "Оборище": "Oborishte",

    "Овча Купел": "Ovcha Kupel",

    "Овчарски": "Ovcharski",

    "Огоста": "Ogosta",

    "Околчица": "Okolchitsa",

    "Окръжна болница": "Regional Hospital",

    "Окръжна болница-Генерали": "Regional Hospital-Generals",

    "Операта": "Opera",

    "Опълченски": "Opalchenski",

    "Орел": "Orel",

    "Орландовци": "Orlandovtsi",

    "Орлова чука": "Orlova Chuka",

    "Орфей": "Orfei",

    "Освобождение": "Osvobozhdenie",

    "Осогово": "Osogovo",

    "Островна промишлена зона": "Island Industrial Zone",

    "Остромила": "Ostromila",

    "Отдих и култура": "Recreation and Culture",

    "Павлово": "Pavlovo",

    "Пазара": "The Market",

    "Пазарджик": "Pazardzhik",

    "Пазарджишко шосе": "Pazardzhishko Road",

    "Панония": "Panonia",

    "Парлева махала": "Parleva Mahala",

    "Парта 1": "Parta 1",

    "Парта 2": "Parta 2",

    "Перник": "Pernik",

    "Пети километър": "Peti Kilometar",

    "Петко Каравелов": "Petko Karavelov",

    "Петко Славейков": "Petko Slaveikov",

    "Петровица": "Petrovitsa",

    "Пещерско шосе": "Peshtersko Road",

    "Пинета": "Pineta",

    "Планова промишлена зона": "Planned Industrial Zone",

    "Плевен": "Pleven",

    "Плиска": "Pliska",

    "Пловдив": "Plovdiv",

    "Победа": "Pobeda",

    "Погреби": "Pogrebi",

    "Подбалканска": "Podbalkanska",

    "Подуяне": "Poduyane",

    "Пожарната": "The Fire Station",

    "Поройни": "Poroyni",

    "Пригаров район": "Prigarov District",

    "Прилепци": "Prileptsi",

    "Пристанище Бургас": "Port of Burgas",

    "Пристанище Варна": "Port of Varna",

    "Продимчец": "Prodimchetz",

    "Пройновци": "Proynovtsi",

    "Пром. зона - Трандевица": "Industrial Zone - Trandevitsa",

    "Промишлена зона": "Industrial Zone",

    "Промишлена зона - А": "Industrial Zone - A",

    "Промишлена зона - Б": "Industrial Zone - B",

    "Промишлена зона - Запад": "Industrial Zone - West",

    "Промишлена зона - Изток": "Industrial Zone - East",

    "Промишлена зона - Лозово": "Industrial Zone - Lozovo",

    "Промишлена зона - Север": "Industrial Zone - North",

    "Промишлена зона - Юг": "Industrial Zone - South",

    "Промишлена зона Тополи": "Industrial Zone Topoli",

    "Прослав": "Proslav",

    "Проучване": "Prouchvane",

    "Първа промишлена зона": "First Industrial Zone",

    "Пъстрина": "Pastrina",

    "Радина чешма": "Radina Cheshma",

    "Разград": "Razgrad",

    "Разсадника": "Rasadnika",

    "Райна Княгиня": "Rayna Knyaginya",

    "Ралица": "Ralitsa",

    "Рачевци": "Rachevtsi",

    "Редута": "Reduta",

    "Резбарци": "Rezbartsi",

    "Река Лева": "Reka Leva",

    "Ремиза": "Remiza",

    "Рено": "Reno",

    "Република": "Republika",

    "Речица": "Rechitsa",

    "Рилци": "Riltsi",

    "Римска гробница": "Roman Tomb",

    "Рогошко шосе": "Rogoshko Road",

    "Родина 1": "Rodina 1",

    "Родина 2": "Rodina 2",

    "Родина 3": "Rodina 3",

    "Родина 4": "Rodina 4",

    "Ромска махала": "Roma Mahala",

    "Рудник": "Rudnik",

    "Рудничар": "Rudnichar",

    "Румена войвода": "Rumena Voyvoda",

    "Русе": "Ruse",

    "Русевци": "Rusevtsi",

    "Руски": "Ruski",

    "Самара 1": "Samara 1",

    "Самара 2": "Samara 2",

    "Самара 3": "Samara 3",

    "Самуил": "Samuil",

    "Сарани": "Sarani",

    "Сарафово": "Sarafovo",

    "Сарая": "Saraya",

    "Света гора": "Sveta Gora",

    "Свети Георги": "Saint George",

    "Свети Иван Рилски": "Sveti Ivan Rilski",

    "Свети Никола": "Saint Nikola",

    "Свобода": "Svoboda",

    "Север": "Sever",

    "Северен парк": "Northern Park",

    "Северна промишлена зона": "Northern Industrial Zone",

    "Селото": "Seloto",

    "Сениче": "Seniche",

    "Сердика": "Serdika",

    "Силистра": "Silistra",

    "Симеоново": "Simeonovo",

    "Сини камъни": "Sini Kamani",

    "Сирмани": "Sirmani",

    "Складова база": "Warehouse Base",

    "Скобелева майка": "Skobeleva Maika",

    "Славейков": "Slaveykov",

    "Славия": "Slavia",

    "Славовци": "Slavovtsi",

    "Слатина": "Slatina",

    "Сливен": "Sliven",

    "Смирненски": "Smirnenski",

    "Смолян": "Smolyan",

    "София": "Sofia",

    "Спортна зала": "Sports Hall",

    "Средна кула": "Sredna Kula",

    "Средок": "Sredok",

    "Ставропол": "Stavropol",

    "Стадион Спартак": "Spartak Stadium",

    "Станевска махала": "Stanevska Mahala",

    "Стар център": "Old Center",

    "Стара Загора": "Stara Zagora",

    "Стара Тева": "Stara Teva",

    "Стара част": "Old Part",

    "Стария град": "Old Town",

    "Старият град": "Old Town",

    "Стефан Караджа": "Stefan Karadzha",

    "Стефан Стамболов": "Stefan Stambolov",

    "Стойковци": "Stoykovtsi",

    "Столипиново": "Stolipinovo",

    "Сторгозия": "Storgozia",

    "Сточна гара": "Stockyard Station",

    "Стоян Заимов": "Stoyan Zaimov",

    "Стрелбище": "Strelbishte",

    "Строител": "Stroitel",

    "Струмско": "Strumsko",

    "Студен кладенец": "Studen Kladenets",

    "Студентски град": "Student Town",

    "Студентско градче": "Student Town",

    "Сухата река": "Suhata Reka",

    "Сухата река 1": "Suhata Reka 1",

    "Сухата река 2": "Suhata Reka 2",

    "Сухата река 3": "Suhata Reka 3",

    "Съдийски": "Sadiyski",

    "Съдийски квартал": "Sadiyski Kvartal",

    "Съединение": "Saedinenie",

    "Табана": "Tabana",

    "Твърди ливади": "Tvrdi Livadi",

    "Тева": "Teva",

    "Терзиите": "Terziite",

    "Тлъчници": "Tlachnitsi",

    "Товарна гара": "Freight Station",

    "Томбул джамия": "Tombul Mosque",

    "Тончевци": "Tonchevtsi",

    "Топхане": "Tophane",

    "Тракийски": "Trakiyski",

    "Тракия": "Trakia",

    "Тракцията": "Traktsiyata",

    "Трендафил 1": "Trendafil 1",

    "Трендафил 2": "Trendafil 2",

    "Три чучура": "Tri Chuchura",

    "Три чучура - север": "Tri Chuchura - North",

    "Три чучура - център": "Tri Chuchura - Center",

    "Три чучура - юг": "Tri Chuchura - South",

    "Трошево": "Troshevo",

    "Търговище": "Targovishte",

    "Търговия на едро": "Wholesale Trade",

    "Търсиите": "Tarsiite",

    "Устово": "Ustovo",

    "Устрем": "Ustrem",

    "Училищни": "Uchilishni",

    "Фестивален комплекс": "Festival Complex",

    "Филипово": "Filipovo",

    "Фондови жилища": "Fondovi Zhilishta",

    "Френк хисар": "Frenk Hisar",

    "ХЕИ": "HEI",

    "Хаджи Димитър": "Hadji Dimitar",

    "Хаджи Хасан махала": "Hadji Hasan Mahala",

    "Хаджицонев мост": "Hadzhitsonev Bridge",

    "Хале": "Hale",

    "Хан Крум": "Han Krum",

    "Харман махала": "Harman Mahala",

    "Хасково": "Haskovo",

    "Херсон": "Kherson",

    "Химик": "Himik",

    "Хиподрума": "Hippodroma",

    "Хисаря": "Hisarya",

    "Хранително-вкусова зона": "Food Zone",

    "Христо Ботев": "Hristo Botev",

    "Христо Смирненски": "Hristo Smirnenski",

    "Хумни дол": "Humni Dol",

    "Хъшове": "Hushove",

    "Цариградско шосе": "Tsarigradsko Road",

    "Цветен квартал": "Tsveten Kvartal",

    "Цветница": "Tsvetnitsa",

    "Централен кооп. пазар": "Central Coop. Market",

    "Централен южен район": "Central Southern District",

    "Централна гара": "Central Station",

    "Централна поща": "Central Post Office",

    "Център": "Center",

    "Църква": "Tsarkva",

    "Чайка": "Chaika",

    "Чародейка - Север": "Charodeyka - North",

    "Чародейка - Юг": "Charodeyka - South",

    "Чаталджа": "Chataldzha",

    "Червен бряг": "Cherven Bryag",

    "Червена стена": "Chervena Stena",

    "Червената пръст": "Red Soil",

    "Черно море": "Cherno More",

    "Чехлевци": "Chehlevtsi",

    "Чишкарева махала": "Chishkareva Mahala",

    "Чолаковци": "Cholakotsi",

    "Шекер махала": "Sheker Mahala",

    "Шенини": "Shenini",

    "Широк център": "Wide Center",

    "Шумачка махала": "Shumachka Mahala",

    "Шумен": "Shumen",

    "Юг": "Yug",

    "Южен": "Yuzhen",

    "Южна дъга": "Yuzhna Daga",

    "Ябълка": "Yabalka",

    "Ябълките": "Yabulkite",

    "Яворов": "Yavorov",

    "Ялта": "Yalta",

    "Ямбол": "Yambol",

    "в.з. 9-ти километър": "Villa Zone 9th Kilometer",

    "в.з. Акджамия": "Villa Zone Akdzhamia",

    "в.з. Бабаковец": "Villa Zone Babakovets",

    "в.з. Бистрец": "Villa Zone Bistrets",

    "в.з. Д. Басарбовски": "Villa Zone D. Basarbovski",

    "в.з. Запад": "Villa Zone West",

    "в.з. Касева Чешма": "Villa Zone Kaseva Cheshma",

    "в.з. Кръста": "Villa Zone Krusta",

    "в.з. Кулата": "Villa Zone Kulata",

    "в.з. Кумбаир": "Villa Zone Kumbair",

    "в.з. Левента": "Villa Zone Leventa",

    "в.з. Липака": "Villa Zone Lipaka",

    "в.з. Лозята": "Villa Zone Lozata",

    "в.з. Образцов Чифлик": "Villa Zone Obraztsov Chiflik",

    "в.з. Русофили": "Villa Zone Rusofili",

    "в.з. Север": "Villa Zone North",

    "в.з. Синан тепе": "Villa Zone Sinan Tepe",

    "в.з. Сливен": "Villa Zone Sliven",

    "в.з. Смесе": "Villa Zone Smese",

    "в.з. Смолян": "Villa Zone Smolyan",

    "в.з. Средна кула": "Villa Zone Sredna Kula",

    "в.з. Трите гълъба": "Villa Zone Trite Galaba",

    "в.з. Хасково": "Villa Zone Haskovo",

    "в.з. Юг": "Villa Zone South",

    "в.з.Боровете": "Villa Zone Borovete",

    "в.з.Брястовете": "Villa Zone Bryastovete",

    "в.з.Виница - север": "Villa Zone Vinitsa - North",

    "в.з.Димчево": "Villa Zone Dimchevo",

    "в.з.Звездица": "Villa Zone Zvezditsa",

    "в.з.Лозницата": "Villa Zone Loznitsata",

    "в.з.Острица": "Villa Zone Ostritsa",

    "в.з.Черниците": "Villa Zone Chernitsite",

    "гр. Българово": "Bulgarevo",

    "к.к. Златни пясъци": "Golden Sands Resort",

    "к.к. Св.Св. Константин и Елена": "Saints Constantine and Helena Resort",

    "к.к. Слънчев ден": "Sunny Day Resort",

    "к.к. Чайка": "Chaika Resort",

    "м-ст Бадемите": "Bademite Area",

    "м-ст Батака": "Batakata Area",

    "м-ст Върли Бряг": "Varli Bryag Area",

    "м-ст Каменица": "Kamenitsa Area",

    "м-ст Каптажа": "Kaptazha Area",

    "м-ст Капчето": "Kapcheto Area",

    "м-ст Минерални бани": "Mineral Baths Area",

    "м-ст Отманли": "Otmanli Area",

    "м-ст Погребите": "Pogrebite Area",

    "м-ст Сухото дере": "Sukhoto Dere Area",

    "м-ст Ченгене Скеле": "Chengene Skele Area",

    "м-ст Червени Рид": "Cherveni Rid Area",

    "м-ст Шилото": "Shiloto Area",

    "м-т Акчелар": "Akchelar Area",

    "м-т Ален мак": "Alen Mak Area",

    "м-т Атанас Тарла": "Atanas Tarla Area",

    "м-т Балам Дере": "Balam Dere Area",

    "м-т Беш Бунар": "Besh Bunar Area",

    "м-т Боклук Тарла": "Bokluk Tarla Area",

    "м-т Боровец - север": "Borovets - North Area",

    "м-т Боровец - юг": "Borovets - South Area",

    "м-т Водни градини": "Water Gardens Area",

    "м-т Газибаба": "Gazibaba Area",

    "м-т Голеш": "Golesh Area",

    "м-т Горна Трака": "Gorna Traka Area",

    "м-т Добрева чешма": "Dobreva Cheshma Area",

    "м-т Долна Трака": "Dolna Traka Area",

    "м-т Евксиноград": "Euxinograd Area",

    "м-т Зеленика": "Zelenika Area",

    "м-т Кантара": "Kantara Area",

    "м-т Кожухарска чешма": "Kozhuharska Cheshma Area",

    "м-т Козлуджа": "Kozludzha Area",

    "м-т Кочмар": "Kochmar Area",

    "м-т Крушките": "Krushkite Area",

    "м-т Лазур": "Lazur Area",

    "м-т Манастирски рид": "Manastirski Rid Area",

    "м-т Ментешето": "Mentesheto Area",

    "м-т Минково": "Minkovo Area",

    "м-т Мъртва долина": "Martva Dolina Area",

    "м-т Орехчето": "Orehcheto Area",

    "м-т Орлето": "Orleto Area",

    "м-т Палековото": "Palekovoto Area",

    "м-т Перчемлията": "Perchemliata Area",

    "м-т Планова": "Planova Area",

    "м-т Плочата": "Plochata Area",

    "м-т Под Манастира": "Pod Manastira Area",

    "м-т Прибой": "Priboy Area",

    "м-т Припек": "Pripek Area",

    "м-т Пчелина": "Pchelina Area",

    "м-т Ракитника": "Rakitnika Area",

    "м-т Сакарка": "Sakarka Area",

    "м-т Салтанат": "Saltanat Area",

    "м-т Сотира": "Sotira Area",

    "м-т Стражата": "Strazhata Area",

    "м-т Сълзица": "Salzitsa Area",

    "м-т Телевизионна кула": "Television Tower Area",

    "м-т Фичоза": "Fichoza Area",

    "м-т Франга Дере": "Franga Dere Area",

    "м-т Чашка": "Chashka Area",

    "с. Братово": "Bratovo",

    "с. Брястовец": "Bryastovets",

    "с. Димчево": "Dimchevo",

    "с. Драганово": "Draganovo",

    "с. Звездица": "Zvezditsa",

    "с. Извор": "Izvor",

    "с. Изворище": "Izvorishte",

    "с. Казашко": "Kazashko",

    "с. Каменар": "Kamenar",

    "с. Константиново": "Konstantinovo",

    "с. Маринка": "Marinka",

    "с. Миролюбово": "Mirolubovo",

    "с. Равнец": "Ravnets",

    "с. Твърдица": "Tvarditsa",

    "с. Тополи": "Topoli",

}